import { template as template_f12ab170fa8b4fea929a4f0371015f9c } from "@ember/template-compiler";
const FKControlMenuContainer = template_f12ab170fa8b4fea929a4f0371015f9c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
