import { template as template_be81eca838494e29beeec8404e031f1d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_be81eca838494e29beeec8404e031f1d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
