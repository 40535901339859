import { template as template_edb26e4d872a4a6db52f9ad99b6527a0 } from "@ember/template-compiler";
import UserLink from "discourse/components/user-link";
import avatar from "discourse/helpers/avatar";
import icon from "discourse/helpers/d-icon";
const ReviewableCreatedBy = template_edb26e4d872a4a6db52f9ad99b6527a0(`
  <div class="created-by">
    {{#if @user}}
      <UserLink @user={{@user}}>{{avatar @user imageSize="large"}}</UserLink>
    {{else}}
      {{icon "trash-can" class="deleted-user-avatar"}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ReviewableCreatedBy;
